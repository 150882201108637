<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2 space-y-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <form
        class="space-y-6 pb-4"
        @submit.prevent="submit"
        @keydown="removeError($event.target.id)"
        @input="removeError($event.target.id)">
        <h3 class="text-2xl">
          {{ isInscription ? 'Re' : isUpdating ? 'Actualizar' : 'Crear' }} inscripción
        </h3>

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Datos del alumno
          </h3>

          <a-input
              id="id_document"
              required
              :label="`Cédula ${loadingStudents ? 'Buscando...' : '' }`"
              :loading="loadingStudents"
              @input="handleTypingSearch"
              :error="error('id_document', { attribute: 'cédula' })"
              v-model="preinscription.id_document" />

          <a-input
            label="Nombre"
            required
            :disabled="loadingStudents"
            id="first_name"
            :error="error('first_name', { attribute: 'nombre' })"
            v-model.number="preinscription.first_name" />

          <a-input
            label="Apellido"
            required
            :disabled="loadingStudents"
            id="last_name"
            :error="error('last_name', { attribute: 'apellido' })"
            v-model="preinscription.last_name" />

          <template v-if="!isUpdating">
            <a-input
              required
              label="Correo electrónico"
              :disabled="loadingStudents"
              type="email"
              id="email"
              :error="error('email', { attribute: 'correo electrónico' })"
              v-model="preinscription.email" />
          </template>

          <a-select
            label="Género"
            required
            :disabled="loadingStudents"
            :source="[{label: 'Masculino', value: 'male'}, {label: 'Femenino', value: 'female'}]"
            :error="error('gender', { attribute: 'género' })"
            v-model="preinscription.gender" />

          <div>
            <div class="flex">
              <label class="font-medium text-gray-700 block mb-2">
                Turno
              </label>
              <span class="text-red-600 inline-block ml-1 font-bold">
                *
            </span>
            </div>

            <v-select
              :loading="loadings.enabledPeriod"
              :options="period.settings.schedule_shifts"
              :reduce="$0 => $0.value"
              v-model="preinscription.schedule_shift" />
            <p class="text-red-500" v-if="error('schedule_shift')">{{ error('schedule_shift', { attribute: 'turno' }) }}</p>
          </div>

          <a-input
            label="Teléfono"
            :disabled="loadingStudents"
            id="phone"
            :error="error('phone', { attribute: 'teléfono' })"
            v-model="preinscription.phone" />

          <a-input
              label="Teléfono celular"
              required
              :disabled="loadingStudents"
              id="mobile_phone"
              :error="error('mobile_phone', { attribute: 'teléfono celular' })"
              v-model="preinscription.mobile_phone" />

          <a-input
            label="Lugar de nacimiento"
            required
            :disabled="loadingStudents"
            id="birth_place"
            :error="error('birth_place', { attribute: 'lugar de nacimiento' })"
            v-model="preinscription.birth_place" />

          <a-input
            label="Fecha de nacimiento"
            required
            :disabled="loadingStudents"
            id="birth_date"
            type="date"
            :error="error('birth_date', { attribute: 'fecha de nacimiento', format: (value) => value, date: (value) => value })"
            v-model="preinscription.birth_date" />

          <a-input
            label="Dirección"
            required
            :disabled="loadingStudents"
            id="address"
            :error="error('address', { attribute: 'turno' })"
            v-model="preinscription.address" />
        </div>

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Redes sociales
          </h3>
          <a-input
            label="Instagram"
            :disabled="loadingStudents"
            id="address"
            :error="error('social_networks.instagram', { attribute: 'instagram' })"
            v-model="preinscription.social_networks.instagram" />

          <a-input
            label="Facebook"
            :disabled="loadingStudents"
            id="address"
            :error="error('social_networks.facebook', { attribute: 'facebook' })"
            v-model="preinscription.social_networks.facebook" />
          <a-input
            label="Twitter"
            :disabled="loadingStudents"
            id="address"
            :error="error('social_networks.twitter', { attribute: 'twitter' })"
            v-model="preinscription.social_networks.twitter" />
        </div>

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Estudios realizados
          </h3>

          <a-select
            label="Educación"
            required
            :disabled="loadingStudents"
            :source="[{label: 'Media', value: 'medium'}, {label: 'Superior', value: 'superior'}]"
            :error="error('educational_background', { attribute: 'educación' })"
            v-model="preinscription.educational_background" />

          <a-input
            label="Título obtenido"
            required
            :disabled="loadingStudents"
            id="educational_title"
            :error="error('educational_title', { attribute: 'título obtenido' })"
            v-model="preinscription.educational_title" />

          <a-input
            label="Actividad actual"
            required
            :disabled="loadingStudents"
            id="educational_current_activity"
            :error="error('educational_current_activity', { attribute: 'actividad actual' })"
            v-model="preinscription.educational_current_activity" />

          <a-input
            label="Nombre de instituto"
            required
            :disabled="loadingStudents"
            id="educational_institution_name"
            :error="error('educational_institution_name', { attribute: 'nombre de instituto' })"
            v-model="preinscription.educational_institution_name" />

          <a-input
            label="Fecha de graduación"
            required
            :disabled="loadingStudents"
            id="educational_graduation_date"
            type="date"
            :error="error('educational_graduation_date', { attribute: 'fecha de graduación', format: (value) => value, date: (value) => value })"
            v-model="preinscription.educational_graduation_date" />

          <a-select
            label="Especialidad"
            required
            :disabled="loadingStudents"
            @input="removeError('specialization_id')"
            :loading="loadingSpecialization"
            :source="specializations.map(el => ({ label: el.title, value: el.id })).filter(el => el.value !== 1)"
            :error="error('specialization_id', { attribute: 'especialidad' })"
            v-model="preinscription.specialization_id"/>
        </div>

        <!-- ------------------------------------- -->

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Datos del representante
          </h3>
          <a-input
              label="Nombre del representante"
              required
              :disabled="loadingStudents"
              id="representative_name"
              :error="error('representative_name', { attribute: 'nombre del representante' })"
              v-model="preinscription.representative_name" />

          <a-input
            label="Email del representante"
            required
            :disabled="loadingStudents"
            id="representative_email"
            :error="error('representative_email', { attribute: 'email del representante' })"
            v-model="preinscription.representative_email" />

          <a-input
            label="Profesión del representante"
            required
            :disabled="loadingStudents"
            id="representative_profession"
            :error="error('representative_profession', { attribute: 'profesión del representante' })"
            v-model="preinscription.representative_profession" />

          <a-input
            label="Dirección del representante"
            required
            :disabled="loadingStudents"
            id="representative_address"
            :error="error('representative_address', { attribute: 'dirección del representante' })"
            v-model="preinscription.representative_address" />
        </div>

        <!-- ------------------------------------------ -->

        <div class="space-y-4">
          <h3 class="font-bold text-gray-800 text-lg">
            Datos de pago
          </h3>
          <a-input
            label="Fecha de pago"
            required
            :disabled="loadingStudents"
            id="payment_date"
            type="date"
            :error="error('payment_date', { attribute: 'fecha de pago', format: (value) => value, date: (value) => value })"
            v-model="preinscription.payment_date" />
          <a-input
            label="Total pagado"
            required
            :disabled="loadingStudents"
            type="number"
            id="payment_amount"
            :error="error('payment_amount', { attribute: 'total pagado' })"
            v-model.number="preinscription.payment_amount" />
          <a-select
            label="Tipo de pago"
            required
            :disabled="loadingStudents"
            :source="[{label: 'Efectivo', value: 1}, {label: 'Transferencia bancaria', value: 3}, {label: 'Zelle', value: 5}]"
            :error="error('payment_type', { attribute: 'turno' })"
            v-model="preinscription.payment_type" />
          <a-input
                label="Número de factura"
                required
                :disabled="loadingStudents"
                id="payment_receipt_number"
                :error="error('payment_receipt_number', { attribute: 'número de factura' })"
                v-model="preinscription.payment_receipt_number" />
        </div>

        <div class="flex space-x-2">
          <a-button mode="primary" type="submit" :loading="loading">
            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
          </a-button>

          <a-button outlined :to="{ name: 'preinscriptions' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    preinscription: {
      first_name: '',
      last_name: '',
      email: '',
      id_document: '',
      gender: '',
      schedule_shift: '',
      address: '',
      birth_place: '',
      birth_date: '',
      phone: '',
      mobile_phone: '',
      payment_date: '',
      payment_amount: '',
      payment_type: '',
      payment_receipt_number: '',
      educational_title: '',
      educational_background: '',
      educational_institution_name: '',
      educational_graduation_date: '',
      educational_current_activity: '',
      representative_email: '',
      representative_name: '',
      representative_profession: '',
      representative_address: '',
      period_id: '',
      social_networks: {
        twitter: '',
        facebook: '',
        instagram: ''
      }
    },
    isUpdating: false,
    timeout: null,
    isInscription: false,
    loadings: {
      enabledPeriod: false
    },
    period: {
      settings: {
        schedule_shifts: []
      }
    }
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      lang: 'lang'
    }),
    ...mapState({
      preinscriptions: state => state.preinscriptions.pagination.data,
      currentPreinscriptionOnState: state => state.preinscriptions.current,
      currentStudentOnState: state => state.students.current,
      loading: state => state.preinscriptions.loading,
      // loadingPeriods: state => state.periods.loading,
      loadingSpecialization: state => state.specializations.loading,
      // periods: state => state.periods.all,
      specializations: state => state.specializations.all,
      loadingStudents: state => state.students.loading
    })
  },
  methods: {
    ...mapMutations({
      setStudent: 'students/SET'
    }),
    ...mapActions({
      fetchPreinscription: 'preinscriptions/fetch',
      // fetchPerios: 'periods/index',
      fetchSpecializations: 'specializations/fetchSpecializations',
      store: 'preinscriptions/store',
      update: 'preinscriptions/update',
      removeError: 'removeError',
      fetchStudents: 'students/index',
      fetchStudent: 'students/show',
      reInscript: 'students/reInscript',
    }),
    handleTypingSearch(search) {
      if (!this.isUpdating) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          if (search) {
            this.fetchStudents({ search, limit: 0 })

          } else {
            Object.keys(this.preinscription).forEach($0 => {
              this.preinscription[$0] = ''
            })
          }
        }, 500);
      }
    },
    submit() {
      console.log(this.isInscription, this.isUpdating)
      if (this.route.params.id) {
        this.reporsitory
      } else {
        if (this.isInscription) {
          this.reInscript(this.preinscription)
          return
        }

        if (this.isUpdating) {
          this.update(this.preinscription)
        } else {
          this.store(this.preinscription)
        }
      }
    }
  },
  watch: {
    currentPreinscriptionOnState(val) {
      this.preinscription = {...val}
    },
    students(val) {
      let found = val.find(it => it.id_document.includes(this.preinscription.id_document))

      this.setStudent({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        schedule_shift: '',
        address: '',
        birth_place: '',
        birth_date: '',
        phone: '',
        mobile_phone: '',
        fax: '',
        payment_date: '',
        payment_amount: '',
        payment_type: '',
        payment_receipt_number: '',
        educational_title: '',
        educational_background: '',
        educational_institution_name: '',
        educational_graduation_date: '',
        educational_current_activity: '',
        representative_email: '',
        representative_name: '',
        representative_profession: '',
        representative_address: '',
        period_id: '',
        id_document: this.preinscription.id_document,
        social_networks: {
          instagram: '',
          facebook: '',
          twitter: ''
        }
      })

      this.isInscription = false

      if (found && this.preinscription.id_document !== '') {
        this.setStudent(found)
        this.isInscription = true
      }
    },
    currentStudentOnState(val) {
      this.preinscription = val
    }

  },
  created() {
    const id = this.$route.query.update
    // this.fetchPerios({ limit: 0, enabled: true })
    this.fetchSpecializations({ limit: 0 })

    this.loadings.enabledPeriod = !this.loadings.enabledPeriod
    this.$repository.periods
      .enabled()
      .then(({ data }) => {
        this.period = {
          ...data,
          settings: {
            ...data.settings,
            schedule_shifts: data.settings.schedule_shifts.map($0 => ({
              label: this.lang.common.schedule_shifts[$0],
              value: $0
            }))
          }
        }
      })
      .finally(() => this.loadings.enabledPeriod = !this.loadings.enabledPeriod)

    if (this.$route.params.id) {
      this.fetchStudent({ id: this.$route.params.id })
        .then((val) => {
          console.log(val)
        })
      // console.log(this.currentStudentOnState)
    } else {
      if (id) {
        this.isUpdating = true

        let found = this.preinscriptions.find(preinscription => preinscription.id === parseInt(id))

        if (found) {
          if (!found.social_networks) {
            found.social_networks = this.preinscription.social_networks
          }

          this.preinscription = {...found}
        } else {

          this.fetchPreinscription({
            id,
            query: {
              with: `socialNetworks`
            }
          })
        }
      }
    }

  }
}
</script>
