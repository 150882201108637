<template>
  <div class="flex flex-col items-center">
<!--    <div class="w-full xl:w-1/2 space-y-2">-->
    <div class="w-full space-y-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <form
          class="space-y-6 pb-4"
          @submit.prevent="submit"
          @keydown="removeError($event.target.id)"
          @input="removeError($event.target.id)">
        <h3 class="text-2xl text-center">
          {{ $route.params.id ? 'Reincorporar Estudiante' : 'Crear Reincorporación' }}
        </h3>

        <h3 class="font-bold text-gray-800 text-lg">
          Datos del estudiante
        </h3>
        <div class="flex">
          <div class="flex-1 space-y-4 mx-2">
<!--            <a-input-->
<!--                id="id_document"-->
<!--                required-->
<!--                :label="`Cédula ${loadingStudents ? 'Buscando...' : '' }`"-->
<!--                :loading="loadingStudents"-->
<!--                :disabled="isEditing"-->
<!--                @input="handleTypingSearch"-->
<!--                :error="error('id_document', { attribute: 'cédula' })"-->
<!--                v-model="student.id_document" />-->
            <a-input
                id="id_document"
                required
                :label="`Cédula ${loadingStudents ? 'Buscando...' : '' }`"
                @search="searchStudent($event)"
                :disabled="isEditing || loadings.student"
                :error="errors.student"
                v-model="student.id_document" >

              <template #suffix>
                <a-loader v-if="loadings.student" class="h-6 w-6 text-gray-300 mr-2"/>
              </template>
            </a-input>

            <a-input
                label="Nombre"
                required
                :disabled="isEditing || foundedStudent"
                id="first_name"
                :error="error('first_name', { attribute: 'nombre' })"
                v-model.number="student.first_name" />

            <a-input
                label="Apellido"
                required
                :disabled="isEditing || foundedStudent"
                id="last_name"
                :error="error('last_name', { attribute: 'apellido' })"
                v-model="student.last_name" />

            <template v-if="!isUpdating">
              <a-input
                  required
                  label="Correo electrónico"
                  :disabled="isEditing || foundedStudent"
                  type="email"
                  id="email"
                  :error="error('student.email', { attribute: 'correo electrónico' })"
                  v-model="student.email" />
            </template>

            <a-select
                label="Género"
                required
                :disabled="isEditing || foundedStudent"
                :source="[{label: 'Masculino', value: 'male'}, {label: 'Femenino', value: 'female'}]"
                :error="error('gender', { attribute: 'género' })"
                v-model="student.gender" />

            <div>
              <div class="flex">
                <label class="font-medium text-gray-700 block mb-2">
                  Turno
                </label>
<!--                <span class="text-red-600 inline-block ml-1 font-bold">-->
<!--                *-->
<!--                </span>-->
              </div>

              <v-select
                  :disabled="isEditing || foundedStudent"
                  :options="period.settings.schedule_shifts"
                  :reduce="$0 => $0.value"
                  v-model="student.schedule_shift" />
              <p class="text-red-500" v-if="error('schedule_shift')">{{ error('schedule_shift', { attribute: 'turno' }) }}</p>
            </div>

            <a-input
                label="Teléfono"
                :disabled="isEditing || foundedStudent"
                id="phone"
                :error="error('phone', { attribute: 'teléfono' })"
                v-model="student.phone" />

            <a-input
                label="Teléfono celular"
                required
                :disabled="isEditing || foundedStudent"
                id="mobile_phone"
                :error="error('mobile_phone', { attribute: 'teléfono celular' })"
                v-model="student.mobile_phone" />

            <a-input
                label="Lugar de nacimiento"
                required
                :disabled="isEditing || foundedStudent"
                id="birth_place"
                :error="error('birth_place', { attribute: 'lugar de nacimiento' })"
                v-model="student.birth_place" />

            <a-input
                label="Fecha de nacimiento"
                required
                :disabled="isEditing || foundedStudent"
                id="birth_date"
                type="date"
                :error="error('birth_date', { attribute: 'fecha de nacimiento', format: (value) => value, date: (value) => value })"
                v-model="student.birth_date" />

            <a-input
                label="Dirección"
                required
                :disabled="isEditing || foundedStudent"
                id="address"
                :error="error('address', { attribute: 'turno' })"
                v-model="student.address" />

            <h3 class="font-bold text-gray-800 text-lg">
              Redes sociales
            </h3>
            <a-input
                label="Instagram"
                :disabled="isEditing || foundedStudent"
                id="address"
                :error="error('social_networks.instagram', { attribute: 'instagram' })"
                v-model="student.social_networks.instagram" />

            <a-input
                label="Facebook"
                :disabled="isEditing || foundedStudent"
                id="address"
                :error="error('social_networks.facebook', { attribute: 'facebook' })"
                v-model="student.social_networks.facebook" />
            <a-input
                label="Twitter"
                :disabled="isEditing || foundedStudent"
                id="address"
                :error="error('social_networks.twitter', { attribute: 'twitter' })"
                v-model="student.social_networks.twitter" />
            <h3 class="font-bold text-gray-800 text-lg">
              Estudios realizados
            </h3>

            <a-select
                label="Educación"
                required
                :disabled="isEditing || foundedStudent"
                :source="[{label: 'Media', value: 'medium'}, {label: 'Superior', value: 'superior'}]"
                :error="error('educational_background', { attribute: 'educación' })"
                v-model="student.educational_background" />

            <a-input
                label="Título obtenido"
                required
                :disabled="isEditing || foundedStudent"
                id="educational_title"
                :error="error('educational_title', { attribute: 'título obtenido' })"
                v-model="student.educational_title" />

            <a-input
                label="Actividad actual"
                required
                :disabled="isEditing || foundedStudent"
                id="educational_current_activity"
                :error="error('educational_current_activity', { attribute: 'actividad actual' })"
                v-model="student.educational_current_activity" />

            <a-input
                label="Nombre de instituto"
                required
                :disabled="isEditing || foundedStudent"
                id="educational_institution_name"
                :error="error('educational_institution_name', { attribute: 'nombre de instituto' })"
                v-model="student.educational_institution_name" />

            <a-input
                label="Fecha de graduación"
                required
                :disabled="isEditing || foundedStudent"
                id="educational_graduation_date"
                type="date"
                :error="error('educational_graduation_date', { attribute: 'fecha de graduación', format: (value) => value, date: (value) => value })"
                v-model="student.educational_graduation_date" />

            <h3 class="font-bold text-gray-800 text-lg">
              Datos del representante
            </h3>
            <a-input
                label="Nombre del representante"
                required
                :disabled="isEditing || foundedStudent"
                id="representative_name"
                :error="error('representative_name', { attribute: 'nombre del representante' })"
                v-model="student.representative_name" />

            <a-input
                label="Email del representante"
                required
                :disabled="isEditing || foundedStudent"
                id="representative_email"
                :error="error('representative_email', { attribute: 'email del representante' })"
                v-model="student.representative_email" />

            <a-input
                label="Profesión del representante"
                required
                :disabled="isEditing || foundedStudent"
                id="representative_profession"
                :error="error('representative_profession', { attribute: 'profesión del representante' })"
                v-model="student.representative_profession" />

            <a-input
                label="Dirección del representante"
                required
                :disabled="isEditing || foundedStudent"
                id="representative_address"
                :error="error('representative_address', { attribute: 'dirección del representante' })"
                v-model="student.representative_address" />
          </div>
          <div class="flex-1 space-y-4 mx-2">

            <span class="font-medium block">Periodo</span>
            <a-period-accessor
                v-model="inscription.period_id"
                @input="changedPeriod"
                class="w-full"/>

            <a-select
                label="Pensum"
                required
                :disabled="loadingPensums"
                @input="changePensum"
                :loading="loadingPensums"
                :source="pensums.map(el => ({ label: el.name, value: el.id }))"
                v-model="inscription.pensum_id"/>

            <a-select
                v-if="pensum"
                label="Semestre"
                required
                :disabled="loadingPensums"
                @input="changeSemestre"
                :loading="loadingPensums"
                :source="pensum.semesters.map(el => ({ label: el.number, value: el.id }))"
                v-model="inscription.semester_id"/>

<!--            <div>-->
<!--              <label for="is_repeating" class="block font-medium">¿Está repitiendo?</label>-->
<!--              <a-switch id="is_repeating" class="mb-4 w-full" v-model="inscription.is_repeating" />-->
<!--            </div>-->

            <h3 class="font-bold text-gray-800 text-lg">
              Datos de pago
            </h3>
            <a-input
                label="Fecha de pago"
                required
                :disabled="loadingStudents"
                id="payment_date"
                type="date"
                :max="getDate"
                :error="error('payment_date', { attribute: 'fecha de pago', format: (value) => value, date: (value) => value })"
                v-model="inscription.payment_date" />
            <a-input
                label="Total pagado"
                required
                :disabled="loadingStudents"
                type="number"
                id="payment_amount"
                :min="0"
                :error="error('payment_amount', { attribute: 'total pagado' })"
                v-model.number="inscription.payment_amount_e2" />
            <a-select
                label="Tipo de pago"
                required
                :disabled="loadingStudents"
                :source="[{label: 'Efectivo', value: 1}, {label: 'Transferencia bancaria', value: 3}, {label: 'Zelle', value: 5}]"
                :error="error('payment_type', { attribute: 'turno' })"
                style="min-height: 40px; !important"
                v-model="inscription.payment_type" />
            <a-input
                v-if="inscription.payment_type !== 1"
                label="Número de factura"
                required
                :disabled="loadingStudents"
                id="payment_receipt_number"
                :error="error('payment_receipt_number', { attribute: 'número de factura' })"
                v-model="inscription.payment_receipt_number" />
            <div class="flex space-x-2">
              <a-button mode="primary" type="submit" :loading="loading">
                {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
              </a-button>

              <a-button outlined :to="{ name: 'preinscriptions' }">
                Cancelar
              </a-button>
            </div>
          </div>
        </div>

        <div class="space-y-4">

        </div>

        <!-- ------------------------------------- -->

        <div class="space-y-4">

        </div>

        <!-- ------------------------------------------ -->
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import moment from "../../filters/moment";

export default {
  data: () => ({
    inscription: {
      payment_date: '',
      payment_amount_e2: '',
      payment_type: '',
      payment_receipt_number: '',
      period_id: '',
      semester_id: '',
      pensum_id: '',
    },
    student: {
      first_name: '',
      last_name: '',
      email: '',
      id_document: '',
      gender: '',
      schedule_shift: '',
      address: '',
      birth_place: '',
      birth_date: '',
      phone: '',
      mobile_phone: '',
      educational_title: '',
      educational_background: '',
      educational_institution_name: '',
      educational_graduation_date: '',
      educational_current_activity: '',
      representative_email: '',
      representative_name: '',
      representative_profession: '',
      representative_address: '',
      social_networks: {
        twitter: '',
        facebook: '',
        instagram: ''
      },
    },
    isUpdating: false,
    timeout: null,
    isInscription: false,
    foundedStudent: null,
    loadings: {
      enabledPeriod: false,
      student: false,
    },
    errors: {
      student: '',
      isRetired: false,
    },
    period: {
      settings: {
        schedule_shifts: []
      }
    },
    isEditing: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      lang: 'lang'
    }),
    ...mapState({
      // preinscriptions: state => state.preinscriptions.pagination.data,
      currentPreinscriptionOnState: state => state.preinscriptions.current,
      currentStudentOnState: state => state.students.current,
      loading: state => state.preinscriptions.loading,
      pensums: state => state.pensums.all,
      pensum: state => state.pensums.pensum,
      // loadingPeriods: state => state.periods.loading,
      loadingSpecialization: state => state.specializations.loading,
      loadingPensums: state => state.pensums.loading,
      // periods: state => state.periods.all,
      specializations: state => state.specializations.all,
      loadingStudents: state => state.students.loading
    }),
    getDate() {
      return moment(new Date(), 'YYYY/MM/DD')
    },
    getError() {
      let val = ''
      if (this.student.id_document) {
        console.log(this.errors.isRetired)
        if (this.errors.isRetired) {
          val = ''
        } else {
          val = 'Error, este estudiante se encuentra activo'
        }
        console.log(val)
      }
      return val
    },
  },
  methods: {
    ...mapMutations({
      setStudent: 'students/SET'
    }),
    ...mapActions({
      fetchPreinscription: 'preinscriptions/fetch',
      // fetchPerios: 'periods/index',
      fetchSpecializations: 'specializations/fetchSpecializations',
      store: 'preinscriptions/store',
      update: 'preinscriptions/update',
      removeError: 'removeError',
      fetchStudents: 'students/index',
      fetchStudent: 'students/show',
      fetchPensums: 'pensums/index',
      fetchPensum: 'pensums/show',
      reInscript: 'students/reInscript',
    }),
    searchStudent(e) {
      if (e) {
        this.loadings.student = true
        this.errors.isRetired = false
        this.foundedStudent = null
        this.errors.student = ''

        Object.keys(this.student).forEach((val) => {
          if (val !== 'id_document') {
            this.student[val] = ''
          }
            // this.student.social_networks.facebook = undefined
            // this.student.social_networks.instagram = undefined
            // this.student.social_networks.twitter = undefined
        })

        this.$repository.students.index({search: e})
          .then(({ data }) => {
            if (data.data.length === 1 && data.data[0]?.id_document === this.student.id_document) {
              this.foundedStudent = true
              this.errors.isRetired = data.data[0].is_retired

              if (!this.errors.isRetired) {
                this.errors.student = 'Error, el estudiante se encuentra activo'
              } else {
                // console.log(Object.keys(this.student))
                Object.keys(this.student).forEach((val) => {
                  // console.log(val)
                  this.student[val] = data.data[0][val]
                })
                console.log(this.student)
                // this.student = { ...data.data }
              }

              // this.errors.student =
              // if (this.errors.isRetired) {
              //
              // }
              console.log(data.data[0].is_retired)
                // AQUI VA EL CODIGO PARA MONTAR LOS DATOS del estudiante encontrado
            } else if(data.data.length > 1){
              this.foundedStudent = false
            }
          })
          .finally(() => {
            this.loadings.student = false
          })
      }
    },
    changedPeriod(e) {
      // this.preinscription.pensum = 0
        if (e) {
          this.inscription.pensum_id = ''
          this.fetchPensums({ limit: 0, period: this.inscription.period_id, order_by: 'id,asc' })
        }
    },
    changePensum(e) {
      this.inscription.semester_id = ''
      this.fetchPensum({ id: e, query: { with: 'semesters.enabledCourses' } })
    },
    changeSemestre(e) {
      // console.log(e)
      this.inscription.semester_id = e
    },
    // 'preinscription.period_id'(val){
    //   // this.preinscription.pensum = undefined
    //
    // },
    handleTypingSearch(search) {
      if (!this.isUpdating) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          if (search) {
            this.fetchStudents({ search, limit: 0 })

          } else {
            Object.keys(this.preinscription).forEach($0 => {
              this.preinscription[$0] = ''
            })
          }
        }, 500);
      }
    },
    submit() {
      let request = {}
      if (this.$route.params.id) {
        request = {
          ...this.inscription,
          specialization_id: this.pensums.find(($0) => $0.id === this.inscription.pensum_id).specialization_id,
          payment_amount_e2: this.inscription.payment_amount_e2 * 100,
          student_id: parseInt(this.$route.params.id),
        }
      } else {
        request = {
          ...this.inscription,
          specialization_id: this.pensums.find(($0) => $0.id === this.inscription.pensum_id).specialization_id,
          payment_amount_e2: this.inscription.payment_amount_e2 * 100,
          student: {
            ...this.student
          },
        }
      }
      console.log(request)

      this.$repository.inscriptions.inscript(request)
        .then(() => {
          this.$message.success({
            message: `Se ha creado la reincorporacón con éxito`,
          })
          this.$router.push({ name: 'students', query: { search: this.student.id_document } })
        })
      //   console.log(val)
      // })
    }
  },
  watch: {
    // currentPreinscriptionOnState(val) {
    //   this.preinscription = {...val}
    // },
    // students(val) {
    //   let found = val.find(it => it.id_document.includes(this.preinscription.id_document))
    //
    //   this.setStudent({
    //     first_name: '',
    //     last_name: '',
    //     email: '',
    //     gender: '',
    //     schedule_shift: '',
    //     address: '',
    //     birth_place: '',
    //     birth_date: '',
    //     phone: '',
    //     mobile_phone: '',
    //     fax: '',
    //     payment_date: '',
    //     payment_amount: '',
    //     payment_type: '',
    //     payment_receipt_number: '',
    //     educational_title: '',
    //     educational_background: '',
    //     educational_institution_name: '',
    //     educational_graduation_date: '',
    //     educational_current_activity: '',
    //     representative_email: '',
    //     representative_name: '',
    //     representative_profession: '',
    //     representative_address: '',
    //     period_id: '',
    //     id_document: this.preinscription.id_document,
    //     social_networks: {
    //       instagram: '',
    //       facebook: '',
    //       twitter: ''
    //     }
    //   })
    //
    //   this.isInscription = false
    //
    //   if (found && this.preinscription.id_document !== '') {
    //     this.setStudent(found)
    //     this.isInscription = true
    //   }
    // },
    currentStudentOnState(val) {
      this.student = val
    }
  },
  created() {
    // this.fetchPerios({ limit: 0, enabled: true })
    // this.fetchSpecializations({ limit: 0 })

    this.isEditing = !!this.$route.params.id

    this.loadings.enabledPeriod = !this.loadings.enabledPeriod
    this.$repository.periods
        .enabled()
        .then(({ data }) => {
          this.period = {
            ...data,
            settings: {
              ...data.settings,
              schedule_shifts: data?.settings?.schedule_shifts?.map($0 => ({
                label: this.lang.common.schedule_shifts[$0],
                value: $0
              }))
            }
          }
        })
        // .finally(() => {
        //   this.fetchPensums({ limit: 0, period: this.period.id })
        //   this.loadings.enabledPeriod = !this.loadings.enabledPeriod
        // })

    if (this.$route.params.id) {
      this.fetchStudent({ id: this.$route.params.id })
    }
  }
}
</script>
